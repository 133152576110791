.Carousel_Item {
  padding: 0 12px;
}

.react-multi-carousel-track > *[aria-hidden="false"]:first-child {
  padding-left: 0;
}

.react-multi-carousel-track > *[aria-hidden="false"]:last-child {
  padding-right: 0;
}

.Carousel_Dot,
.Carousel_Dot__Inactive {
  padding: 4px;
  margin: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
}

.Carousel_Dot__Inactive {
  cursor: pointer;
}

.Carousel_Footnotes {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  color: var(--dark-grey);
  padding-left: 40px;
}

.Carousel_ButtonGroup {
  padding-top: 16px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 40px;
}

.Carousel_ChevronButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 1; /* so that buttons are above the dots container */
}

.Carousel_Container {
  position: relative;
  width: calc(100% + 40px + 40px);
  overflow: hidden;
  margin-left: -40px;
}

.react-multi-carousel-dot-list {
  bottom: 7px; /* to align the dots with the buttons */
}

.react-multi-carousel-list {
  margin-left: 38px;
  margin-right: 38px;
  padding-left: 0;
  overflow: visible;
}
