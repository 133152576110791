.ImpactByAssessmentChartCard {
  border: var(--interface-grey) 1px solid;
  box-shadow: none !important;
  height: 100%;
  padding: 16px 40px !important;
  max-height: 320px;
}

.ImpactByAssessmentChartCard_Tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
}

.ImpactByAssessmentChartCard_Tooltip_Row {
  display: flex;
  gap: 4px;
  align-items: baseline;
}

.ImpactByAssessmentChartCard_Tooltip_Comparison_Row {
  display: flex;
  gap: 6px;
  align-items: center;
}
