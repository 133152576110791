.SalesDashboardPage_Header {
  top: 0;
}

.SalesDashboardPageContent {
  display: grid;
  gap: 24px;
  padding: 24px;
}

.SalesDashboardPageControls {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.SalesDashboardCard {
  background-color: var(--white);
  display: grid;
  padding: 16px 24px 12px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--interface-grey);
  flex: 1 0 0;
}
