.percentage-lozenge {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px 4px;
  gap: 2px;
  color: black;
}

.percentage-lozenge.neutral {
  background-color: rgba(255, 255, 255, 1);
}

.percentage-lozenge.positive {
  background-color: rgba(210, 239, 221, 1);
}

.percentage-lozenge.negative {
  background-color: rgba(252, 210, 207, 1);
}
