.grid-recipe-status {
  --icon-width: 42px;
  display: flex;
}

.grid-recipe-status .icon-wrapper {
  width: var(--icon-width);
  margin-top: auto;
  margin-bottom: auto;
}

.grid-recipe-status .icon-wrapper .icon {
  width: var(--icon-width);
}

.grid-recipe-status p {
  background: #ffffff;
  color: #000000;
  /* font-size: 11px; */
  margin: 0;
  padding-bottom: 4px;
  padding-top: 4px;
}

.recipe-message {
  padding-left: 6px;
  padding-right: 6px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 6px;
  line-height: 20px;
  border: none;
  display: none;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.grid-recipe-status .needs-attention {
  background: var(--interface-attention);
  color: #000;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.grid-recipe-status .internal-error {
  background: var(--interface-error);
}

.grid-recipe-status .pending,
.grid-recipe-status .unavailable {
  padding-left: 4px;
  padding-right: 4px;
}

.sharing-icon {
  min-height: 22px;
  min-width: 22px;
}

.grid-card {
  min-height: 6.7rem;
}

.result-grid {
  width: 100%;
  min-width: 190px;
  height: 248px;
  min-height: 248px;
  max-height: 248px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 12px;
}

.result-grid:hover {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
}

.result-grid:hover .grid-recipe-status .recipe-message {
  display: block;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* This is limiting the tags in 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  line-height: 1.5em;
  word-break: break-word;
}

.ResultsGrid_StaleImpact {
  opacity: 30%;
}

.ResultsGrid_GridItemProgressBarContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  color: black;
}

.ResultsGrid_GridItem_SpinnerContainer {
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translate(-50%, 0px);
}

.ResultsGrid_GridItem_Spinner {
  width: 32px;
  height: 32px;
}

.TagsContainer {
  color: var(--black, #000);
  font-size: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  gap: 8px;
}

.effect-value {
  color: #000;
  margin: 0px;
}

.effect-unit {
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
}

.effect-container {
  flex-grow: 1;
  gap: 4px;
  align-items: baseline;
}

.TagContainer--limited {
  max-width: 150px;
  height: 24px;
}
.TagContainer--full {
  /* width: -webkit-fill-available; */
  max-width: 100%;
  height: 24px;
}

.TagTextContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 12px;
}

.TagTextContainer--selected {
  background: var(--white);
}

.TagArrow--selected {
  border-left: 9px solid var(--white);
}

.TagTextContainerCounter {
  /* color: var(--dark-turquoise); */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 24px;
}
.TagArrow {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}
