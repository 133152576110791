.impact-rating-display {
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  gap: 8px;
  height: 28px;
  /* small copy */
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.impact-rating-display__very-high {
  background: var(--label-E);
  color: #ffffff;
}

.impact-rating-display__high {
  background: var(--label-D);
  color: #ffffff;
}

.impact-rating-display__medium {
  background: var(--label-C);
  color: #000000;
}

.impact-rating-display__low {
  background: var(--label-B);
  color: #000000;
}

.impact-rating-display__very-low {
  background: var(--label-A);
  color: #ffffff;
}
