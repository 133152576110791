.ReportSummaryCard,
.ReportSummaryCard_WithComparison {
  padding: 12px 16px 8px 16px;
  gap: 8px;
  height: 158px;
}

.ReportSummaryCard_Icon {
  height: 32px;
}

.ReportSummaryCard_TotalImpact {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.ReportSummaryCard_Comparison {
  display: flex;
  align-items: center;
  gap: 8px;
}

.PercentageLozenge {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 2px;
  border-radius: 4px;
  background-color: var(--white);
}

.PercentageLozenge .Arrow {
  color: var(--foodsteps-turquoise);
}

.ReportSummaryCard_ComparisonText {
  color: var(--dark-grey);
}
