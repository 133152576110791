.ChartsContainer_title_overtime {
}

.ChartsContainer_period_options {
  border-radius: 8px;
  background: var(--Main-colours-Bone, #f4f4f0);
  width: fit-content;
  height: fit-content;
  margin: 4px;
  display: flex;
}

.ChartsContainer_period_option {
  border-radius: 8px;
  background: var(--Main-colours-Bone, #f4f4f0);
  width: fit-content;
  height: fit-content;
  margin: 4px;
  display: flex;
  border: none;
  color: var(--Main-colours-Dark-Turquoise, #10675b);
}

/* Default hover style */
.ChartsContainer_period_option:not(:disabled):hover {
  background-color: var(--Main-colours-Bone, #f4f4f0) !important;
  color: var(--foodsteps-turquoise) !important;
}

/* Prevent hover effect if selected */
.ChartsContainer_period_option_selected {
  background: var(--Main-colours-Dark-Turquoise, #10675b);
  color: var(--Main-colours-White, #fff);
  pointer-events: none; /* Optional: prevents interaction */
}
.ChartsContainer_period_option_selected:hover {
  background: var(--Main-colours-Dark-Turquoise, #10675b);
  color: var(--Main-colours-White, #fff);
  pointer-events: none; /* Optional: prevents interaction */
}
