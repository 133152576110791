.AverageImpactIntensityByTag {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ImpactByRating_Tooltip {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 4px;
  padding: 8px;
  width: fit-content;
}

.AverageIntensityByTag_Tooltip_Label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.AverageIntensityByTag_Tooltip_Impact {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  white-space: nowrap;
  gap: 4px;
  align-items: baseline;
}

.AverageIntensityByTag_Tooltip_ImpactValue {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.AverageIntensityByTag_Tooltip_ItemCount {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 4px;
  padding-bottom: 0;
}

/* ///////////////// */

.ImpactByRating_Tooltip_color_Label_value {
  color: var(--white);

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.ImpactByRating_Tooltip_color {
  width: 10px;
  height: 10px;
}
.ImpactByRating_Tooltip_color_container {
  align-items: center;
  gap: 5px;
}

.ImpactByRating_Tooltip_color_Label_unit {
  color: var(--white);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.ImpactByRating_Tooltip_date_title {
  color: var(--white);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.ImpactByRating_Tooltip_total {
  color: #fff;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 109.091% */
}

.ImpactByRating_Tooltip_total_unit {
  color: var(--white);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.ImpactByRating_Tooltip_sales_unit {
  color: var(--white);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.ImpactByRating_Tooltip_sales {
  color: var(--white);

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
