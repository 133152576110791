.ChevronButton {
  color: var(--dark-turquoise);
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
}

.ChevronButton:hover {
  cursor: pointer;
  color: var(--foodsteps-turquoise);
}

.ChevronButton_Disabled,
.ChevronButton_Disabled:hover {
  color: var(--dark-turquoise);
  opacity: 0.3;
}
