// Typography defined in https://www.figma.com/file/LyX9wbSKd81UvXFeq58wMx/Foodsteps-Rebrand-Design-Library?type=design&node-id=1723-1512&mode=design&t=dY9xIS4pxbbEYKgl-0

// Font weights
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Headings
h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: $font-weight-medium;
}

h2 {
  font-size: 23px;
  line-height: 30px;
  font-weight: $font-weight-medium;
}

h3 {
  font-size: 21px;
  line-height: 28px;
  font-weight: $font-weight-medium;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: $font-weight-medium;
}

.body-copy-medium {
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 24px;
}

.small-copy,
.small-copy-medium {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.small-copy-medium {
  font-weight: 500;
}

.small-graph-legends {
  font-size: 12px;
  line-height: 12px;
  font-weight: $font-weight-normal;
}

.ExtraLargeNumber {
  font-size: 32px;
  line-height: 40px;
  font-weight: $font-weight-medium;
}

.LargeNumber {
  font-size: 28px;
  line-height: 40px;
  font-weight: $font-weight-medium;
}

.Number {
  font-size: 22px;
  line-height: 24px;
  font-weight: $font-weight-medium;
}
