.ProcurementDashboard_Header {
  top: 0;
}

.ProcurementDashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 24px;
}
